import { reactive, ref, Ref, shallowReadonly } from 'vue';

type UseRequest<TData, TParams = undefined> = Readonly<{
  data: TData;
  triggerFetch: (params?: TParams) => Promise<void>;
  isLoading: boolean;
  error: string | undefined;
}>;

export const useRequest = <TData, TParams = undefined>(
  fetchData: (params?: TParams) => Promise<TData>,
  defaultData: TData
): UseRequest<TData, TParams> => {
  const data = ref<TData>(defaultData);
  const error = ref<string | undefined>();
  const isLoading = ref<boolean>(false);

  const triggerFetch = async (params?: TParams) => {
    isLoading.value = true;
    try {
      data.value = await fetchData(params);
    } catch (err) {
      console.error('use-request.triggerFetch error: ', err);
      error.value = `${err}`;
    } finally {
      isLoading.value = false;
    }
  };

  const res = shallowReadonly(
    reactive({
      data: data as Ref<TData>,
      isLoading,
      error,
      triggerFetch,
    })
  );
  return res;
};
