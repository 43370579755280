<script setup lang="ts">
import { WsCard, WsButton } from '@mfl/common-components';

import strings from '../employee.strings';

function assignSignatureHandler() {
  // TODO: open assign signature modal
}
</script>
<template>
  <ws-card>
    <div class="add-signature-content">
      <ws-button
        aid="ASSIGN_SIGNATURE_BUTTON"
        variant="contained"
        size="sm"
        color="primary"
        @click="assignSignatureHandler()"
      >
        {{ strings.assignSignature }}
      </ws-button>
    </div>
  </ws-card>
</template>
<style lang="scss" scoped>
.add-signature-content {
  width: 529px;
  height: 352px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
