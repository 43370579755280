<script setup lang="ts">
import { computed } from 'vue';
import { navigateTo } from '@mfl/framework';

import { EmployeeGroup } from '@msl/employee-group-gateway-sdk';
import { WsButton } from '@mfl/common-components';
import { Employee } from '../../../employee-types';

import employeeStatus from './employee-status.vue';
import employeeGroupSelect from './employee-group-select.vue';
import employeePhoto from './employee-photo.vue';
import employeeOptionsMenu from './employee-options-menu.vue';
import backButton from './back-button.vue';

import { EMPLOYEE_STATUS_VALUES } from '../../../employee-types';
import strings from '../employee.strings';

const props = defineProps<{
  groupsList: Array<EmployeeGroup>;
  employeeInfo: Employee;
}>();

const emit = defineEmits(['update-employee-status', 'update-employee-group']);

const photo = computed(() => {
  if (
    'photo' in props.employeeInfo.employeeData &&
    props.employeeInfo.employeeData['photo']
  ) {
    return props.employeeInfo.employeeData['photo'];
  }
  return '';
});

const isStatusActive = computed(() => {
  return props.employeeInfo.status == EMPLOYEE_STATUS_VALUES.ACTIVE;
});

const groupId = computed(() => props.employeeInfo.groupId);

function updateEmployeeStatusHandler(val: boolean) {
  emit('update-employee-status', val);
}

function updateEmployeeGroupHandler(val: string) {
  emit('update-employee-group', val);
}

function editEmployeeDetailsClickHandler() {
  navigateTo(`/employess/${props.employeeInfo.employeeId}/details`);
}
</script>
<template>
  <div class="employee-page-header">
    <back-button />
    <div class="employee-page-header-content">
      <div class="employe-photo-name-wrapper">
        <div class="employee-photo-wrapper">
          <employee-photo
            :photo="photo"
            :name="props.employeeInfo.name"
            :text-class="['text-md', 'font-bold']"
          />
        </div>
        <p class="text-2xl font-bold">
          {{ props.employeeInfo.name }}
        </p>
      </div>
      <div class="v-line-divider"></div>
      <employee-status
        v-model:model-value="isStatusActive"
        @update:model-value="updateEmployeeStatusHandler"
      />
      <div class="v-line-divider"></div>
      <employee-group-select
        v-model:model-value="groupId"
        :groups-list="props.groupsList"
        @update:model-value="updateEmployeeGroupHandler"
      />
      <div class="right-wrapper">
        <ws-button
          aid="EDIT_EMPLOYEE_DETAILS_BUTTON"
          size="sm"
          color="primary"
          variant="contained"
          @click="editEmployeeDetailsClickHandler"
        >
          {{ strings.editDetailsButton }}
        </ws-button>
        <employee-options-menu
          :is-integrated="props.employeeInfo.isIntegrated"
          :creation-method="props.employeeInfo.creationMethod"
          :auto-inject="props.employeeInfo.autoInject"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.employee-page-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid #e0e0e0;

  .employee-page-header-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;

    .v-line-divider {
      width: 1px;
      height: 16px;
      background-color: #2d2e30;
    }

    .employe-photo-name-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      .employee-photo-wrapper {
        width: 31px;
        height: 31px;
        overflow: hidden;
      }
    }

    .right-wrapper {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }
  }
}
</style>
