<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  name: string;
  photo?: string;
  textClass?: Array<string>;
}>();

const initials = computed(() => {
  const splitedName = props.name.split(' ');
  if (splitedName.length > 1) {
    return splitedName[0][0].toUpperCase() + splitedName[1][0].toUpperCase();
  } else if (splitedName.length && splitedName[0].length) {
    return splitedName[0][0].toUpperCase();
  }
  return '';
});
</script>
<template>
  <div class="employee-photo">
    <img v-if="props.photo" :src="props.photo" class="employee-img" />
    <p v-else class="employee-name-first-letter" :class="props.textClass">
      {{ initials }}
    </p>
  </div>
</template>
<style lang="scss" scoped>
.employee-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #edeeef;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .employee-img {
    width: 100%;
    height: 100%;
  }

  .employee-name-first-letter {
    color: #828282;
  }
}
</style>
