<script setup lang="ts">
import { computed } from 'vue';

import {
  WsCard,
  WsHtmlViewer,
  WsBadge,
  WsIconButton,
} from '@mfl/common-components';
import { SignatureTypes } from '../../../employee-types';
import strings from '../employee.strings';

const props = defineProps<{
  title: string;
  signatureType: SignatureTypes;
  signatureContent: string;
  showSignatureType?: boolean;
}>();

const badgeLabel = computed(() => {
  if (props.signatureType == SignatureTypes.FORWARD) return strings.forward;
  if (props.signatureType == SignatureTypes.REPLY) return strings.reply;
  if (
    props.signatureType == SignatureTypes.PRIMARY ||
    props.signatureType == SignatureTypes.GROUP
  )
    return strings.primary;
  return '';
});

const badgeIcon = computed(() => {
  if (props.signatureType == SignatureTypes.FORWARD) return 'fa-light fa-share';
  if (props.signatureType == SignatureTypes.REPLY) return 'fa-light fa-reply';
  if (
    props.signatureType == SignatureTypes.PRIMARY ||
    props.signatureType == SignatureTypes.GROUP
  )
    return 'fa-light fa-envelope';
  return '';
});
</script>
<template>
  <ws-card>
    <template #header>
      <div class="signature-header">
        <p class="text-md font-bold">
          {{ props.title }}
        </p>
        <ws-badge
          v-if="props.showSignatureType"
          :aid="'SIGNATURE_BADGE_' + props.signatureType.toUpperCase()"
          :icon="badgeIcon"
          :label="badgeLabel"
          icon-position="start"
          size="sm"
        />
      </div>
    </template>
    <div class="signature-main-content">
      <ws-html-viewer
        :aid="'EMPLOYEE_SIGNATURE_' + props.signatureType"
        :content="props.signatureContent"
        scale="fit"
      />
      <div class="preview-icon-wrapper">
        <span class="preview-icon" />
      </div>
    </div>
    <template #footer>
      <div class="signature-footer">
        <ws-icon-button
          :aid="'EMPLOYEE_SIGNATURE_OPTIONS_ICON_BUTTON' + props.signatureType"
          class="signature-options-icon-btn"
          size="md"
          variant="contained"
          color="primary"
        >
          <span class="fa-regular fa-ellipsis" />
        </ws-icon-button>
      </div>
    </template>
  </ws-card>
</template>
<style lang="scss" scoped>
.signature-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.signature-main-content {
  width: 529px;
  height: 272px;
  margin-left: 10px;
  margin-right: 10px;
  .preview-icon-wrapper {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
}

.signature-footer {
  display: flex;
  justify-content: flex-end;
  height: 21px;
  align-items: center;
  width: 100%;
  position: relative;

  .signature-options-icon-btn {
    position: absolute;
    right: 0;
    top: -5px;
  }
}
</style>
