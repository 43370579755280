import {
  EmployeeGroup,
  employeeGroupGateway,
} from '@msl/employee-group-gateway-sdk';

import { useRequest } from './use-request';

const groupsList = useRequest<Array<EmployeeGroup>, void>(async () => {
  const res = await employeeGroupGateway.getGroupList({});
  return res.result || [];
}, []);

export { groupsList };
