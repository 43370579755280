<script setup lang="ts">
import { ref, computed } from 'vue';

import { OptionsItem, EmployeeCreationMethod } from '../../../employee-types';

import {
  WsIconButton,
  WsSelect,
  WsSelectOptions,
  WsSelectOption,
  WsSelectPanel,
  WsSelectTrigger,
} from '@mfl/common-components';

import strings from '../employee.strings';

const { creationMethod, autoInject, isIntegrated } = defineProps<{
  creationMethod: EmployeeCreationMethod;
  isIntegrated: boolean;
  autoInject?: boolean;
}>();

const value = ref(null);
const menuOptions = computed((): Array<OptionsItem> => {
  const items = [
    {
      key: 'EMPLOYEE_OPTIONS_MENU_SEND_LINK_TO_HUB',
      text: strings.sendLinkToHub,
      icon: 'fa-light fa-envelope',
    },
  ];

  if (isIntegrated && creationMethod === EmployeeCreationMethod.GSUITE) {
    if (autoInject) {
      items.push({
        key: 'EMPLOYEE_OPTIONS_MENU_INJECT_OFF',
        text: strings.injectOff,
        icon: 'fa-light fa-play-pause',
      });
    } else {
      items.push({
        key: 'EMPLOYEE_OPTIONS_MENU_INJECT_ON',
        text: strings.injectOn,
        icon: 'fa-light fa-play-pause',
      });
    }
  }

  return [
    ...items,
    {
      key: 'EMPLOYEE_OPTIONS_MENU_MAKE_ADMIN',
      text: strings.makeAdmin,
      icon: 'fa-light fa-user-plus',
    },
    {
      key: 'EMPLOYEE_OPTIONS_MENU_DELETE_EMPLOYEE',
      text: strings.deleteEmployee,
      icon: 'fa-light fa-trash-can',
    },
  ];
});

function optionLabel(val: OptionsItem) {
  return val.text;
}

function optionKey(val: OptionsItem) {
  return val.key;
}
</script>
<template>
  <ws-select
    v-model="value"
    aid="EMPLOYEE_OPTIONS_MENU"
    :option-label="optionLabel"
    :option-key="optionKey"
    size="lg"
    advanced
  >
    <ws-select-trigger>
      <ws-icon-button aid="EMPLOYEE_OPTIONS_ICON_BUTTON">
        <span class="fa-regular fa-ellipsis" />
      </ws-icon-button>
    </ws-select-trigger>
    <ws-select-panel>
      <ws-select-options>
        <ws-select-option
          v-for="item in menuOptions"
          :key="item.key"
          :value="item"
          class="gap-x-5"
        >
          <div class="employee-options-menu-item-content">
            <span class="item-icon" :class="item.icon" />
            <span class="item-text text-sm font-medium">{{ item.text }}</span>
          </div>
        </ws-select-option>
      </ws-select-options>
    </ws-select-panel>
  </ws-select>
</template>
<style lang="scss" scoped>
.employee-options-menu-item-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .item-icon {
    font-size: 16px;
  }
  .item-text {
    color: #2d2e30;
  }
}
</style>
