<script setup lang="ts">
import { WsButton } from '@mfl/common-components';
import { navigateTo } from '@mfl/framework';

import { getWebAppUrl } from '../../../employee-utils';
import strings from '../employee.strings';

function backButtonClickHandler() {
  navigateTo(getWebAppUrl(), { reload: true });
}
</script>
<template>
  <ws-button
    aid="EMPLOYEE_BACK_BUTTON"
    icon="fa-light fa-arrow-left"
    size="md"
    color="gray-500"
    variant="text"
    @click="backButtonClickHandler()"
  >
    {{ strings.back }}
  </ws-button>
</template>
