export enum EMPLOYEE_STATUS_VALUES {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type OptionsItem = {
  key: string;
  text: string;
  icon: string;
};

export enum SignatureTypes {
  FORWARD = 'forward',
  PRIMARY = 'primary',
  REPLY = 'reply',
  GROUP = 'group',
}

export enum EmployeeCreationMethod {
  MANUAL = 'MANUAL',
  CSV = 'CSV',
  GSUITE = 'GSUITE',
  O365 = 'O365',
  MIGRATION = 'MIGRATION',
}

export type Employee = {
  employeeId: string;
  autoInject?: boolean;
  creationMethod: EmployeeCreationMethod;
  email: string;
  groupId: string;
  isIntegrated: boolean;
  name: string;
  signatureForwardId?: string;
  signatureId: string;
  signatureReplyId?: string;
  status: EMPLOYEE_STATUS_VALUES;
  employeeData: Record<string, string>;
};
