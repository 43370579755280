import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { EMPLOYEES_BASE_URL } from './consts';
import EmployeesPage from './pages/employees/employees-page.vue';
import EmployeePage from './pages/employee/employee-page.vue';

const routes: RouteRecordRaw[] = [
  {
    name: 'employees',
    path: EMPLOYEES_BASE_URL,
    component: EmployeesPage,
  },
  {
    name: 'employee',
    path: `${EMPLOYEES_BASE_URL}/:employeeId`,
    component: EmployeePage,
  },
];

const router = createRouter({
  history: createWebHistory(''),
  routes,
});

export { router };
