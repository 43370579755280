import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';

import mfe from './employees-mfe.vue';
import { router } from './router';
import { employeeGroupGateway } from '@msl/employee-group-gateway-sdk';
import { employeeGatewaySdk } from '@msl/employee-gateway-sdk';
import { auth4Sdks } from '@mfl/platform-shell';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'employees',
  mainComponent: mfe,
  targetElement: '#content',
  includeCss: true,
  handleVueApp: (app) => {
    employeeGroupGateway.init(auth4Sdks());
    employeeGatewaySdk.init(auth4Sdks());
    useQuasarPlugin(app);
    app.use(router);
  },
});

export { bootstrap, mount, unmount };
