<script setup lang="ts"></script>

<template>
  <div class="employees-container">
    <RouterView />
  </div>
</template>

<style scoped>
.employees-container {
  display: flex;
  margin: 24px;
  flex-direction: column;
}
</style>
