import { debounce } from 'lodash';
import { WEBAPP_BASE_URL } from '@mfl/platform-shell';

export const getWebAppUrl = (path: string = '/employees') => {
  const normalizedPath = path.startsWith('/') ? path.substring(1) : path;
  return `${WEBAPP_BASE_URL}/${normalizedPath}`;
};

export const createDebouncedHandler = <TValue>(
  fn: (v: TValue) => void,
  timeout: number = 600
) =>
  debounce((val: TValue) => {
    fn(val);
  }, timeout);
