import {
  employeeGatewaySdk,
  SetStatusRequest,
  SetStatusResponse,
} from '@msl/employee-gateway-sdk';

import { useRequest } from './use-request';

const updateEmployeesStatus = useRequest<SetStatusResponse, SetStatusRequest>(
  async (params = {}) => {
    const res = await employeeGatewaySdk.setStatus(params);
    return res || {};
  },
  {}
);

export { updateEmployeesStatus };
