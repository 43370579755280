<script setup lang="ts">
import { computed, DeepReadonly } from 'vue';
import { EmployeeGroup } from '@msl/employee-group-gateway-sdk';
import { WsTooltip } from '@mfl/common-components';
import { currentUser, PermissionTypes } from '@mfl/platform-shell';

import SelectWithSearch from '../../../components/select/select-with-search.vue';
import strings from '../employee.strings';
import { GenericSelectOption } from '../../../components/select/select-types';

const props = defineProps<{
  groupsList: DeepReadonly<Array<EmployeeGroup>>;
}>();

const groupOptions = computed<Array<GenericSelectOption<string>>>(() => {
  return props.groupsList
    .map((g) => {
      return {
        text: g.name || '',
        value: g.groupId || '',
      };
    })
    .sort((a, b) => {
      if (a.text > b.text) return 1;
      return -1;
    });
});

const model = defineModel<string>({ required: true });
</script>
<template>
  <div class="employee-group">
    <p class="text-md font-bold">
      {{ strings.groupLabel }}
    </p>
    <select-with-search
      v-model="model"
      aid="EMPLOYEE_GROUP_SELECT"
      :options="groupOptions"
      :underline="true"
      :disabled="!currentUser.isAllowedTo(PermissionTypes.manageEmployee)"
      variant="text"
    />
    <ws-tooltip
      v-if="!currentUser.isAllowedTo(PermissionTypes.manageEmployee)"
      aid="EMPLOYEE_GROUP_UPDATE_PERMISSION_REQUIRED"
    >
      {{ strings.permissionsRequired }}
    </ws-tooltip>
  </div>
</template>
<style lang="scss" scoped>
.employee-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
</style>
