export default {
  active: 'Active',
  inactive: 'Inactive',
  groupLabel: 'Group:',
  editDetailsButton: 'Edit details',
  sendLinkToHub: 'Send Link to Hub',
  injectOn: 'Injection On',
  injectOff: 'Injection Off',
  makeAdmin: 'Make Admin',
  deleteEmployee: 'Delete',
  back: 'Back',
  forward: 'FORWARD',
  primary: 'PRIMARY',
  reply: 'REPLY',
  assignSignature: 'Assign signature',
  signatures: 'Signatures',
  employeeActivationToastSuccess: 'Employee activated successfully.',
  saveChangesError: 'Could not save changes. Please try again.',
  employeeDeactivationToastSuccess: 'Employee deactivated successfully.',
  changeSaved: 'Changes saved.',
  permissionsRequired: 'Permissions required',
};
