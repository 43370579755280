<script setup lang="ts">
import { onMounted, computed } from 'vue';
import { vLoading, toast, ToastStatus } from '@mfl/common-components';
import { useRoute } from 'vue-router';

import { groupsList } from '../../composables/groups';
import { employeeInfo } from '../../composables/employee';
import { updateEmployeesStatus } from '../../composables/employees';
import { EMPLOYEE_STATUS_VALUES, SignatureTypes } from '../../employee-types';
import strings from './employee.strings';

import employeePageHeader from './components/employee-page-header.vue';
import addSignatureCard from './components/add-signature-card.vue';
import signatureCard from './components/signature-card.vue';

const route = useRoute();
const employeeId = route.params.employeeId as string;

const isLoading = computed(() => {
  return employeeInfo.isLoading || groupsList.isLoading;
});

function updateEmployeeGroupHandler(groupId: string) {
  console.log(groupId);
  toast({
    status: ToastStatus.Completed,
    aid: 'EMPLOYEE_PAGE_GROUP_UPDATE_SUCCESS',
    message: strings.changeSaved,
  });
  employeeInfo.data.groupId = groupId;
  //error
  // toast({
  //       status: ToastStatus.Error,
  //       aid: 'EMPLOYEE_PAGE_STATUS_UPDATE_ERROR',
  //       message: strings.saveChangesError,
  // });
  //
}

async function updateEmployeeStatusHandler(status: EMPLOYEE_STATUS_VALUES) {
  try {
    const statusValue = status
      ? EMPLOYEE_STATUS_VALUES.ACTIVE
      : EMPLOYEE_STATUS_VALUES.INACTIVE;
    await updateEmployeesStatus.triggerFetch({
      employeeIds: [employeeInfo.data.employeeId],
      status: statusValue,
    });
    if (updateEmployeesStatus.data.error) {
      toast({
        status: ToastStatus.Error,
        aid: 'EMPLOYEE_PAGE_STATUS_UPDATE_ERROR',
        message: strings.saveChangesError,
      });
    } else {
      const message = status
        ? strings.employeeActivationToastSuccess
        : strings.employeeDeactivationToastSuccess;
      employeeInfo.data.status = statusValue;
      toast({
        status: ToastStatus.Completed,
        aid: 'EMPLOYEE_PAGE_STATUS_UPDATE_SUCCESS',
        message,
      });
    }
  } catch (_e) {
    toast({
      status: ToastStatus.Error,
      aid: 'EMPLOYEE_PAGE_STATUS_UPDATE_ERROR',
      message: strings.saveChangesError,
    });
  }
}

const signatureContent = '';

onMounted(async () => {
  await groupsList.triggerFetch();
  await employeeInfo.triggerFetch({ employeeId });
  if (employeeInfo.error) {
    // TODO: handle errors(redirect or show error message)
    return;
  }
});
</script>
<template>
  <div v-loading="isLoading">
    <employee-page-header
      v-if="!isLoading"
      :groups-list="groupsList.data"
      :employee-info="employeeInfo.data"
      @update-employee-group="updateEmployeeGroupHandler"
      @update-employee-status="updateEmployeeStatusHandler"
    />
    <p class="text-lg font-bold title">{{ strings.signatures }}</p>
    <div class="signatures-wrapper">
      <signature-card
        :title="`Title ${SignatureTypes.FORWARD}`"
        :signature-content="signatureContent"
        :signature-type="SignatureTypes.FORWARD"
        :show-signature-type="true"
      />
      <signature-card
        :title="`Title ${SignatureTypes.PRIMARY}`"
        :signature-content="signatureContent"
        :signature-type="SignatureTypes.PRIMARY"
        :show-signature-type="true"
      />
      <signature-card
        :title="`Title ${SignatureTypes.REPLY}`"
        :signature-content="signatureContent"
        :signature-type="SignatureTypes.REPLY"
        :show-signature-type="true"
      />
      <add-signature-card />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.signatures-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 14px;
}

.title {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
