<script setup lang="ts">
import { WsToggle, WsTooltip } from '@mfl/common-components';
import { computed } from 'vue';
import { currentUser, PermissionTypes } from '@mfl/platform-shell';

import strings from '../employee.strings';

const model = defineModel<boolean>({ required: true });

const statusTextValue = computed(() => {
  if (model.value) return strings.active;
  return strings.inactive;
});
</script>
<template>
  <div class="employee-status-wrapper">
    <p class="text-md font-bold">
      {{ statusTextValue }}
    </p>
    <ws-toggle
      v-model="model"
      aid="EMPLOYEE_STATUS_TOGGLE"
      :disabled="!currentUser.isAllowedTo(PermissionTypes.manageEmployee)"
    />
    <ws-tooltip
      v-if="!currentUser.isAllowedTo(PermissionTypes.manageEmployee)"
      aid="EMPLOYEE_SET_STATUS_PERMISSIIN_REQUIRED"
      >{{ strings.permissionsRequired }}</ws-tooltip
    >
  </div>
</template>
<style lang="scss" scoped>
.employee-status-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
</style>
