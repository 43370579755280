import {
  employeeGatewaySdk,
  GetEmployeeByIdRequest,
} from '@msl/employee-gateway-sdk';

import {
  Employee,
  EMPLOYEE_STATUS_VALUES,
  EmployeeCreationMethod,
} from '../employee-types';
import { useRequest } from './use-request';

const employeeInfo = useRequest<Employee, GetEmployeeByIdRequest>(
  async (params = {}) => {
    const { employee } = await employeeGatewaySdk.getEmployee(params);
    return {
      employeeId: employee?.employeeId || '',
      autoInject: employee?.autoInject || false,
      creationMethod:
        (employee?.creationMethod as EmployeeCreationMethod) ||
        EmployeeCreationMethod.MANUAL,
      email: employee?.email || '',
      groupId: employee?.groupId || '',
      isIntegrated: employee?.isIntegrated || false,
      name: employee?.name || '',
      signatureForwardId: employee?.signatureForwardId,
      signatureId: employee?.signatureId || '',
      signatureReplyId: employee?.signatureReplyId,
      status: employee?.status as EMPLOYEE_STATUS_VALUES,
      employeeData: employee?.employeeData || {},
    };
  },
  {
    employeeId: '',
    autoInject: false,
    creationMethod: EmployeeCreationMethod.MANUAL,
    email: '',
    groupId: '',
    isIntegrated: false,
    name: '',
    signatureId: '',
    status: EMPLOYEE_STATUS_VALUES.INACTIVE,
    employeeData: {},
  }
);

export { employeeInfo };
